import API from "../../api/index";

export default{
  // 获取版本记录
  getRecord: (params) => {
    return API.requestGet({
      url: "api/common/version/selectAll",
      auth: false,
      params:{
        type:1,
        ...params
      }
    }, false);
  },
  //
  updateRecord: (params) => {
    return API.requestPost({
      url: "api/v2/basic/update",
      data:params
    }, false)
  },
  //是否有新的更新记录
  getNewRecord: params => {
    return API.requestGet({
      params,
      url: "api/v2/user/selectuserversion"
    },false);
  },
}
